import Swal from "sweetalert2"

function UseSideHeader (props) {
    const logout = () =>{
        Swal.fire({
            title:'ต้องการออกจาก Portfolio',
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor: 'blue',

            showCancelButton: true,
            cancelButtonText:'ยกเลิก',
            cancelButtonColor: 'red',
            text: 'ต้องการออกจาก Portfolio นี้ ให้กด "ตกลง" หากอยู่ต่อให้กด "ยกเลิก" '

        }).then((data)=>{
            if(data.isConfirmed){
                localStorage.removeItem('storageLogin')
                localStorage.removeItem('storageClick')
                window.location.href = '/'
            }else{

            }
        })
            // navigate('/')
    }
    const canDowloadFile = () => {
        window.open(process.env.PUBLIC_URL + '/myResume_kittithat-2024.pdf','_blank')
    }
    const canDowloadFile_EN = () => {
        window.open(process.env.PUBLIC_URL + '/kittithat-2024-version-eng.pdf','_blank')
    }
    return (<>
    
    <div className={`header-side height-0 ${props.height ? 'height-100' : ''}`}   /*</>style={{"minHeight": props.height + 'vh'}}*/> 
    <aside className="profile-slide" >
                        <section className="profile-sec-1">
                            <div className="profile-sec-1-header">
                                <div className="img-slide hover"></div>
                            </div>
                            <div className="profile-sec-1-detail">
                                    
                                <section className="mt-2">
                                    <h5 className="color-title">ชื่อ - นามสกุล</h5>
                                    <h6 className="color-content">กิตติทัต  เจริญเขต</h6>
                                    <h6 className="color-content">อายุ 27 ปี</h6>
                                </section>
                                <section className="mt-4">
                                    <h5 className="color-title">ตำแหน่ง</h5>
                                    <h6 className="color-content">Developer</h6>
                                </section>
                                <section className="mt-4">
                                    <h5 className="color-title">ข้อมูลติดต่อ</h5>
                                    <h6><i className="fa-brands fa-line" style={{"color": "green"}}></i>&nbsp; : @tortong1997</h6>
                                    <h6><i className="fa-solid fa-phone" style={{"color": "blue"}}></i>&nbsp; : 0910174918</h6>
                                    <h6><i className="fa-solid fa-envelope"></i>&nbsp; : KittithatCharoenkhet101@gmail.com</h6>
                                </section>
                                <section className="mt-4">
                                    {/* <button className="w3-button w3-orange" onClick={logout}><i className="fa-solid fa-right-to-bracket"></i>  ออกจาก Portfolio</button> */}
                                    <a onClick={canDowloadFile} className="w3-button w3-teal w3-block w3-round-large w3-large" style={{width:"90%",margin:"3px auto"}} download="myResume_kittithat-2024.pdf" ><i className="fa-solid fa-download"></i> ดาวน์โหลด Resume (TH)</a>
                                    <a onClick={canDowloadFile_EN} className="w3-button w3-teal w3-block w3-round-large w3-large" style={{width:"90%",margin:"3px auto"}} download="myResume_kittithat-2024.pdf" ><i className="fa-solid fa-download"></i> ดาวน์โหลด Resume (EN)</a>
                                    <button className="w3-button w3-orange w3-block w3-round-large w3-large"  style={{width:"90%",margin:"3px auto"}} onClick={logout}><i className="fa-solid fa-right-to-bracket"></i>  ออกจาก Portfolio</button>
                          
                                </section>
                            </div>
                        </section>
                    </aside>

    </div>

    </>)
}
export default UseSideHeader;