function Experience() {
    return (
        <>
            <div className="row experience action-on-top">
                {/* <div className="col-md-12">
                    <div className="tab-experience">
                        <h4>ประสบการณ์ทำงาน</h4>
                    </div>
                </div> */}
                <div className="col-md-12 mt-3 table-responsive">

                    <table className="table table-hover">
                        <thead className="bg-info">
                            <tr>
                                <th style={{width:"150px",textAlign:"center",color:"black"}}>ปีที่ทำงาน</th>
                                <th style={{width:"250px",textAlign:"center",color:"black"}}>ชื่อบริษัท</th>
                                <th style={{width:"220px",textAlign:"center",color:"black"}}>ตำแหน่ง</th>
                                <th style={{textAlign:"center",color:"black"}}>หน้าที่หลักๆ</th>
                                {/* <th style={{width:"220px",textAlign:"center",color:"black"}}>เงินเดือนที่ได้รับ</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="experience-f">
                                <td>2019-11 - 2022-02</td>
                                <td>Moserv Company</td>
                                <td>Junior Software Engineer</td>
                                <td>ดูแล Server ขององค์กร การติดตั้ง Apache Mysql และมีหน้าที่ Query ข้อมูลบน Mysql ด้วยภาษา Sql ดึงเป็นรายงานส่งให้ลูกค้า และใช้ Ubuntu , Centos เป็นหลักผ่าน command ใน Linux และอีกหน้าทีคือ Monitor ดูสถานะของ Server ว่า Script ที่ทำงานบน Server ต่างๆยังคงทำงานอยู่ไหม ถ้า Script Down หรือ Server มีปัญหาแจ้งทีมเพื่อ กระจายให้คนที่เกี่ยวข้องเป็นคนที่จะทำการแก้ไข</td>
                                {/* <td>14,000 (ทดลองงาน) <br /> 15,000 (ผ่านทดลองงาน)</td> */}
                            </tr>
                            <tr>
                                <td>2022-04 - 2024-01</td>
                                <td>Bizpotential Company</td>
                                <td>PHP Developer , Developer</td>
                                <td>พัฒนาระบบด้วยภาษา PHP พร้อมกับ Tool ของบริษัท และเป็นโปรแกรมเมอร์ที่ได้ไปประจำที่หน่วยงานของลูกค้ากรมอนามัย กระทรวงสาธารณสุข ดูแลระบบและพัฒนาเว็บไซต์ที่ทางบริษัทพัฒนาให้กับทางกรม</td>
                                {/* <td>
                                    18,000 (ทดลองงาน) <br />
                                    20,000 (ผ่านทดลองงาน)
                                </td> */}
                            </tr>
                            
                            <tr>
                                <td>2024-01 - ปัจจุบัน</td>
                                <td>Netsys and Computer</td>
                                <td>Programmer</td>
                                <td>ทำ Report Excel Macro (VBA) , Crystal Report ให้ลูกค้า โดยดึงข้อมูลจากฐานข้อมูล SAP ด้วยคำสั่ง SQL บน SQL Server โดยการสร้างเป็น Stored Procedure ดึงเป็นรายงานไว้ก่อนที่จะนำไปใช้กับ Crystal Report และ Excel Macro และได้พัฒนา Website ให้กับทางบริษัท</td>
                                {/* <td>
                                    22,000 (ทดลองงาน) <br />
                                    25,000 (ผ่านทดลองงาน)
                                </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className="col-md-12 mt-5">
                    <h4 className="experience-f">
                       <center>
                           " ทุกที่ มีส่วนทำให้ผมเติบโตขึ้นมาได้  ขอบคุณที่เป็นส่วนหนึ่งทำให้ผมเติบโตขึ้นมาครับ "
                        </center> 
                    </h4>
                </div> */}
            </div>
        </>
    )
}
export default Experience;