import { useEffect, useState } from "react";
import Login from "./Login";
import Portfolio from "../Pages/Portfolio";

function Auth(){
    const [isLogin,setLogin] = useState()
    
        useEffect(()=>{
            setLogin(localStorage.getItem('storageLogin'))
            console.log(isLogin);
    
        })

        return (
        <>

          
        </>
    )
}
export default Auth;