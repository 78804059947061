import { useEffect, useState } from "react";
import Auth from "./Auth";
import { useNavigate } from "react-router-dom";
import Portfolio from "../Pages/Portfolio";
import Swal from "sweetalert2";
import '../style.css'
function Login(){
    const [statusUser,setStatusUser] = useState(false)
    const [statusPass,setStatusPass] = useState(true)
    
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')

    const [isLogin,setLogin] = useState()
    
    const navigate = useNavigate()

    const handdleLoginProcess = (e) =>{
        e.preventDefault();
        if(username == 'tortongdev'){
            if(password == '123'){
                Swal.fire({
                    title: 'เข้าสู่ระบบสำเร็จ',
                    text: 'เย้ คุณเข้าสู่ระบบสำเร็จแล้ว ระบบจะนำพาไปยัง Website',
                    confirmButtonAriaLabel: true,
                    confirmButtonColor: 'blue',
                    icon: 'success'
                }).then((status_pass)=>{                    
                    localStorage.setItem('storageClick','education')
                    let storageLogin = localStorage.setItem('storageLogin','id')
                    setLogin(localStorage.getItem('storageLogin'))
                })
                
            }else{
                
                Swal.fire({
                    title: 'เข้าสู่ระบบไม่สำเร็จ',
                    text: 'คุณเข้าสู่ระบบไม่สำเร็จ กรุณาตรวจสอบชื่อผู้ใช้งานและรหัสผ่านครับ',
                    timer: 8000,
                    confirmButtonAriaLabel: true,
                    confirmButtonColor: 'red',
                    icon: 'error'
                })
            }
        }
    }

    const changeUsername = (valueUsername) => {
        if(valueUsername == 'tortongdev'){
            setUsername(valueUsername)
            setStatusPass(false)
        }else{
            setStatusPass(true)
        }   
    }

    useEffect(()=>{
        if(localStorage.getItem('storageLogin')){
            setLogin(localStorage.getItem('storageLogin'))
        }else{
            setLogin('')
        }
        
    },[])


    if (isLogin) {
        return <Portfolio />;
    }else{

    return (
        <>

            <main className='wrapper-login'>
                <section className="login">
                    <center><img src={process.env.PUBLIC_URL + "/logo-login.webp"} width={"500px"} alt="login-images" /></center>  
                    <h3 className="login-header mt-2 mb-3">ตรวจสอบสิทธิ์</h3>
                    <h6 className="mb-3"> &emsp;&emsp; สวัสดีครับ ผมกิตติทัต เป็น Developer ยินดีต้อนรับผู้เข้าเยี่ยมชม Website ของผมนะครับ หากท่านต้องการเข้าเยี่ยมชมสามารถติดต่อขอรับข้อมูลเข้าระบบได้ที่ ข้อมูลติดต่อด้านล่างครับ </h6>
                    <form onSubmit={handdleLoginProcess} >
                    <div className="form-group mb-3">
                        <label htmlFor="Username">ผู้ใช้งาน : </label>
                        <input type="text" name="USERNAME" id="Username" className="w3-input" placeholder="กรอกชื่อผู้ใช้งาน" onChange={(e)=>changeUsername(e.target.value)} disabled={statusUser} required />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="Password">รหัสผ่าน : </label><br />
                        <span className="text-primary mt-2">** ช่องกรอกรหัสผ่านจะใช้งานได้ เมื่อกรอก "ชื่อผู้ใช้งาน" ถูกต้องครับ</span>
                        <input type="password" name="PASSWORD" id="Password" className="w3-input" placeholder="กรอกรหัสผ่าน" onChange={(e)=>setPassword(e.target.value)} disabled={statusPass} required />
                    </div>
                    <div className="form-group mt-3 mb-3">
                        <center>
                            <button type="submit" className="w3-button w3-blue"> <i className="fa-solid fa-right-to-bracket"></i> เข้าสู่ระบบ</button>&nbsp;
                            <button type="reset" className="w3-button w3-orange"> <i className="fa-solid fa-arrows-rotate"></i> รีเซ็ตฟอร์ม</button>
                        </center>
                    </div>

                    <center><h5 className="mt-2">ข้อมูลติดต่อ</h5></center>
                    <div className="grid-contact-login">
                        <span><i className="fa-brands fa-line" style={{"color": "green"}}></i>&nbsp;@tortong1997</span>
                        <span><i className="fa-solid fa-phone" style={{"color": "blue"}}></i>&nbsp;0910174918</span>
                        <span><i className="fa-solid fa-envelope"></i><font style={{"fontSize":".9rem"}}>&nbsp;Kittithat.charoenkhet101@gmail.com</font></span>

                    </div>
                        {/* <footer className="mt-4">
                            <a href="https://validator.w3.org/nu/?doc=https%3A%2F%2Fkittithatwebdev.com" target="_blank"><img style={{height:"40px"}} src="https://www.w3.org/assets/logos/w3c/w3c-no-bars.svg"></img></a>
                        </footer> */}
                    </form>
                </section>
            </main>
           
        </>
    )
}

}
export default Login;