function MySkill() {
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="backend action-on-top">
                        <div className="tab-backend">
                            <h4>BACKEND</h4>
                        </div>
                        <ul>
                            <li>
                                PHP
                                <ul>
                                    <li>มีประสบการณ์ใช้ภาษา PHP จากการทำงาน 2 ปี</li>
                                    <li>สามารถสร้างในรูปแบบ OOP โดยสร้างเป็น Class รวมการทำงานไว้ที่ Method เพื่อนำกลับมาใช้งานซ้ำได้</li>
                                </ul>    
                            </li>
                            <li>
                                Laravel Framework (Version 9,10)
                                <ul>
                                    <li>ศึกษาด้วยตนเอง และเคยนำมาเขียนระบบตอบแบบสอบถามเล็กๆแทนการใช้กระดาษ ให้กับลูกค้า แต่ไม่ได้ใช้ประจำในที่ทำงานครับ</li>
                                </ul>    
                            </li>
                            <li>
                                NodeJS (ExpressJS)
                                <ul>
                                    <li>ได้ความรู้มาจากการศึกษาด้วยตนเอง</li>
                                    <li>Stack ที่ผมใช้งานหลักๆร่วมกับ Node Express Database จะใช้ Mysql ส่วน ORM จะเป็น Sequelize Frontend จะใช้ ReactJS</li>
                                </ul>    
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="backend action-on-down">
                        <div className="tab-backend">
                            <h4>FRONTEND</h4>
                        </div>
                        <ul>
                            <li>
                                HTML,CSS,Javascript
                                <ul>
                                    <li>มีประสบการณ์ใช้ภาษา HTML,CSS,JAVASCRIPT มากกว่า 3 ปี</li>
                                </ul>    
                            </li>
                            <li>
                                Jquery
                                <ul>
                                    <li>มีประสบการณ์ใช้งานมากกว่า 3 ปี</li>
                                </ul>    
                            </li>
                            <li>
                                Bootstrap 
                                <ul>
                                    <li>มีประสบการณ์ใช้งานมากกว่า 3 ปี</li>
                                </ul>    
                            </li>
                            
                            <li>
                                W3C.css 
                                <ul>
                                    <li>มีประสบการณ์ใช้งานมากกว่า 1 ปี</li>
                                </ul>    
                            </li>
                            <li>
                                ReactJS
                                <ul>
                                    <li>มีประสบการณ์ใช้งาน 6 เดือน ในที่ทำงาน</li>
                                </ul>    
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="backend action-on-top">
                        <div className="tab-backend">
                            <h4>TOOLS / OS / Databases</h4>
                        </div>
                        <ul>
                            <li>Navicate</li>
                            <li>Sql Server Management Tool</li>
                            <li>Postman</li>
                            <li>Visual Studio Code</li>
                            <li>Vim</li>
                            <li>FileZilla</li>
                            <li>Crystal Report</li>
                            <li>Git / Github</li>
                            <li>Windows 7 , 10 , 11</li>
                            <li>Linux Ubuntu 16-22</li>
                            <li>Centos Version 6</li>
                            <li>Sql Server</li>
                            <li>Mysql</li>
                            <li>Hana Sql</li>
                        </ul>
                    </div>
                </div>

            </div>
    
        </>
    )
}
export default MySkill;