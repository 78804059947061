function Target (){
    return (
        <>
            <div className="row mt-5 target  action-on-right">
                
                <iframe src={process.env.PUBLIC_URL + "/all_certificate.pdf"} type="all_certificate_for_me"></iframe>
                {/* <div className="col-md-12 mt-4">
                    <ul>
                        <li><h6>ทำงานด้าน Web Developer</h6></li>
                        <li><h6>ได้ลองภาษา Programming  Framework และ Library ใหม่ที่ยังไม่เคยได้ลองใช้งาน</h6></li>
                        <li><h6>ได้พบเพื่อนใหม่ๆ มีคอนเนคชั่นเพิ่ม</h6></li>
                        <li><h6>ต้องการเป็นผู้รับที่ดี และเป็นผู้ให้ที่ดีเพิ่มเติมความรู้ และถ่ายทอดความรู้ออกไป</h6></li>
                    </ul>
                </div> */}
            </div>
        </>
    )
}
export default Target;