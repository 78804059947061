import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import Education from "./Education"
import MySkill from "./MySkill"
import Experience from "./Experience"
import MyWorks from "./MyWorks"
import Target from "./Target"
import UseSideHeader from "./UseSideHeader";
import Swal from "sweetalert2";

function Portfolio(){
    const [getClickMenu,setClickMenu] = useState()
    const navigate = useNavigate();
     
    const setLocalStorage = (value) =>{
        localStorage.setItem('storageClick',value)
        setClickMenu(localStorage.getItem('storageClick'))
        
    }
    const logout = () =>{
        Swal.fire({
            title:'ต้องการออกจาก Portfolio',
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor: 'blue',

            showCancelButton: true,
            cancelButtonText:'ยกเลิก',
            cancelButtonColor: 'red',
            text: 'ต้องการออกจาก Portfolio นี้ ให้กด "ตกลง" หากอยู่ต่อให้กด "ยกเลิก" '

        }).then((data)=>{
            if(data.isConfirmed){
                localStorage.removeItem('storageLogin')
                localStorage.removeItem('storageClick')
                window.location.href = '/'
            }else{

            }
        })
            // navigate('/')
    }

    const [height,setHeight] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    function handleToggleMenu(){
        setIsOpen(!isOpen);
        setHeight(!height)

    }
    // const [bar,setBar] = useState(false)
    
    // const [height,setHeight] = useState(0)
    // const [styleBar, setStyleBar] = useState('')
    // const handleBar = (displays) => {
    //     setBar(displays)
    //     if(bar == true){
            
    //         setStyleBar('block')
    //         setHeight(100)
            
    //         setBar(false)

    //     }else{
    //         setStyleBar('none')
    //         setHeight(0)
    //         setBar(true)
    //     }
    // }
    const canDowloadFile = () => {
        window.open(process.env.PUBLIC_URL + '/myResume_kittithat-2024.pdf','_blank')
    }
    const canDowloadFile_EN = () => {
        window.open(process.env.PUBLIC_URL + '/kittithat-2024-version-eng.pdf','_blank')
    }
    useEffect(()=>{
   
        setClickMenu(localStorage.setItem('storageClick','education'))

        if(localStorage.getItem('storageClick')){
            setClickMenu(localStorage.getItem('storageClick'))
        }
    },[])
    return (
        <>
            <main className="wrapper-portfolio">
                <section className="portfolio">
                    <aside className="profile">
                        <section className="profile-sec-1">
                            <div className="profile-sec-1-header">
                                <h2 className="hello-animation"></h2>
                                {/* <center><h5 className="hello-animation-welcome"></h5></center> */}
                                <div className="img hover"></div>
                            </div>
                            <br />
                            <div className="profile-sec-1-detail">
                                <section>
                                    <h5>ชื่อ - นามสกุล</h5>
                                    <h6>กิตติทัต  เจริญเขต</h6>
                                    <h6>อายุ 27 ปี</h6>
                                </section>
                                <br />
                                <section>
                                    <h5>ตำแหน่ง</h5>
                                    <h6>Developer</h6>
                                </section>
                                <br />
                                <section>
                                    <h5>งานอดิเรก</h5>
                                    <h6>เขียนโค้ด , ออกกำลังกาย</h6>
                                </section>
                                <br />
                                <section>
                                    <h5>ข้อมูลติดต่อ</h5>
                                    <h6><i className="fa-brands fa-line" style={{"color": "green"}}></i>&nbsp; : @tortong1997</h6>
                                    <h6><i className="fa-solid fa-phone" style={{"color": "blue"}}></i>&nbsp; : 0910174918</h6>
                                    <h6><i className="fa-solid fa-envelope"></i>&nbsp; : KittithatCharoenkhet101@gmail.com</h6>
                                    
                                </section>
                                <section className="mt-5">
                                    {/* <button className="w3-button w3-teal w3-block w3-round-large w3-large" style={{width:"90%",margin:"3px auto"}}><i class="fa-solid fa-download"></i> ดาวน์โหลด Resume</button> */}
                                    <a onClick={canDowloadFile} className="w3-button w3-teal w3-block w3-round-large w3-large" style={{width:"90%",margin:"3px auto"}} download="myResume_kittithat-2024.pdf" ><i className="fa-solid fa-download"></i> ดาวน์โหลด Resume (TH)</a>
                                    <a onClick={canDowloadFile_EN} className="w3-button w3-teal w3-block w3-round-large w3-large" style={{width:"90%",margin:"3px auto"}} download="myResume_kittithat-2024.pdf" ><i className="fa-solid fa-download"></i> ดาวน์โหลด Resume (EN)</a>
                                    
                                    <button className="w3-button w3-orange w3-block w3-round-large w3-large"  style={{width:"90%",margin:"3px auto"}} onClick={logout}><i className="fa-solid fa-right-to-bracket"></i>  ออกจาก Portfolio</button>
                                </section>
                            </div>
                        </section>
                    </aside>
                    <section className="wrapper">
                        <header>
                            <div className="title">
                                <h2 id="title">
                                <i className='fa fa-angle-double-right' style={{fontSize:"28px",color:"rgb(32, 181, 201)"}}></i>&nbsp;
                                    {getClickMenu == 'education' && 'การศึกษา'}
                                    {getClickMenu == 'mySkill' && 'ทักษะ'}
                                    {getClickMenu == 'experience' && 'ประสบการณ์'}
                                    {getClickMenu == 'myWorks' && 'ผลงาน'}
                                    {getClickMenu == 'target' && 'ใบประกาศ'}

                                    <i className={`fa-solid fa-bars ${isOpen && 'close'}`} id="barOpen" onClick={()=>handleToggleMenu()} ></i>
                                    <i className={`fa-solid fa-xmark ${isOpen && 'open'}`} id="barClose" onClick={()=>handleToggleMenu()} ></i>
                                </h2>
                            </div>
                            <div className="menu">
                                <nav  style={{paddingInlineStart:"40px"}}>
                                    <ul>
                                        <li onClick={()=>setLocalStorage('education')} className={getClickMenu == 'education' && 'active'}>การศึกษา</li>
                                        <li onClick={()=>setLocalStorage('mySkill')} className={getClickMenu == 'mySkill' && 'active'}>ทักษะ</li>
                                        <li onClick={()=>setLocalStorage('experience')} className={getClickMenu == 'experience' && 'active'}>ประสบการณ์</li>
                                        <li onClick={()=>setLocalStorage('myWorks')} className={getClickMenu == 'myWorks' && 'active'}>ผลงาน</li>
                                        <li onClick={()=>setLocalStorage('target')} className={getClickMenu == 'target' && 'active'}>ใบประกาศ</li>
                                    </ul>
                                </nav>
                            </div>
                        </header>
                        <UseSideHeader display={1} height={height}/>
                        <section className="display-app">
                            {getClickMenu == 'education' && <Education />}
                            {getClickMenu == 'mySkill' && <MySkill />}
                            {getClickMenu == 'experience' && <Experience />}
                            {getClickMenu == 'myWorks' && <MyWorks />}
                            {getClickMenu == 'target' && <Target />}

                        </section>

                    </section>
                </section>
            </main>
        </>
    )
}
export default Portfolio