function Education(){
    return (
        <>
            <div className="row action-on-right">
            <div className="row education">
                <div className="col-md-12">
                   <h4 className="edu-name">วิทยาลัยเทคนิคร้อยเอ็ด</h4>
                    <ul>
                        <li className="edu-qualification">วุฒิการศึกษาปวส</li>
                        <li className="edu-department">สาขาวิชาพัฒนาเว็บเพจ</li>
                        <li className="edu-grade">เกรดเฉลี่ย 3.47</li>
                        <li className="edu-finish-year">ปีที่จบ 2017</li>
                    </ul>
                </div>
            </div>
            <div className="row education">
                <div className="col-md-12">
                   <h4 className="edu-name">มหาวิทยาลัยเทคโนโลยีราชมงคลอีสาน วิทยาเขตขอนแก่น</h4>
                    <ul>
                        <li className="edu-qualification">วุฒิการศึกษา ปริญญาตรี</li>
                        <li className="edu-department">สาขาวิชาระบบสารสนเทศทางคอมพิวเตอร์</li>
                        <li className="edu-grade">เกรดเฉลี่ย 3.23</li>
                        <li className="edu-finish-year">ปีที่จบ 2019</li>
                    </ul>
                </div>
            </div>
        
        </div>
        </>
    )
}
export default Education;