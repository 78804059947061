import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './style.css'
import Pages from './Login/Pages';
import Portfolio from './Pages/Portfolio';
import Auth from './Login/Auth';
import Login from './Login/Login';
import Login_Manual from './Login/Login_Manual';
const router = createBrowserRouter([
  {
    path: '/auth/portfolio',
    element: <Auth />
  },
  {
    path: '/Logout',
    element: <Login />
  },
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/getLogin',
    element: <Login_Manual />
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
