function MyWorks() {
    return (
        <>
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="card action-on-top card-color hover">
                        <div className="card-header">
                            Thai Stop Covid กรมอนามัย
                        </div>
                        <div className="card-body">
                           เป็นเว็บไซต์ที่กรมอนามัยพัฒนาขึ้นในช่วงที่มีโรคระบาท Covid 19 ช่วงที่ผู้คนต้องการอาหารที่สะอาดปลอดภัย หรือแม้กระทั่งสถานประการอื่นๆเช่นสถานบันเทิง ตลาด โรงงาน โรงเรียน อื่นๆ ถ้าผ่านการประเมินด้วยเว็บไซต์ Thai Stop Covid ก็จะได้รับการตรวจจากเจ้าหน้าที่และออกใบรับรองจากทางกรมอนามัยให้ เพื่อยืนยันให้ทางประชาชนมั่นใจได้ว่าปลอดภัยจากโรค ระบบทำเป็นทีมครับรับหน้าที่ทำ Module ระบบประเมิน และเป็น Programmer ไปนั่งประจำที่กรมอนามัย 
                        </div>
                        <div className="card-footer">
                            Link to : <a href="https://stopcovid.anamai.moph.go.th"  target="_black">Thai Stop covid </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card action-on-right card-color hover">
                        <div className="card-header">
                            Dashboard กรมอนามัย
                        </div>
                        <div className="card-body">
                            Dashboard ต่างๆ ที่ได้พัฒนาให้กับทางกรมอนามัย เอาไว้ประชุมและประเมินผล และเอาไว้ให้เจ้าหน้าที่ประจำกรมอนามัยของหน่วยสุขภาพ ของแต่ละจังหวัดดูครับ Dashboard ที่จริงทำ Dashboard ไปหลายตัวแต่ผมขอคัดในส่วนที่สามารถเอามาโชวได้นะครับ บางตัวก็ไม่ได้อยู่บน Production แล้ว
                        </div>
                        <div className="card-footer">
                            Link to : <a href="https://ehs.anamai.moph.go.th/dashboard_survey_response/" target="_black">Dashboard เตรียมความพร้อมนโยบายบัตร 30 บาท</a><br />
                            Link to : <a href="https://ehs.anamai.moph.go.th/dashboard_cyber_security/" target="_black">Dashboard ผลตรวจสอบความมั่นคงปลอดภัยทางไซเบอร์ กรมอนามัย</a><br />
                            Link to : <a href="https://schoolhealth.anamai.moph.go.th/dashboard_edu_clean/" target="_black">Dashboard ผลประเมินสถานศึกษา</a><br />
                        </div>
                    </div>
                </div>    

                <div className="col-md-4">
                    <div className="card action-on-down card-color hover">
                        <div className="card-header">
                            Netsys And Computer Loas
                        </div>
                        <div className="card-body">
                            ในช่วง Week แรกๆที่เข้ามาทำงานที่ Netsys ทางบริษัทให้ผมพัฒนา Website Netsys ซึ่งเป็นอีกสาขาของบริษัทที่ประเทศเพื่อบ้าน สปป.ลาว (โปรเจคนี้ผมนำลิ้งที่เป็นตัว Develop มานะครับ เนื่องจากตัวจริงลูกค้ายังไม่นำขึ้น Production)
                        </div>
                        <div className="card-footer">
                            Link to : <a href="https://atsamat101dev.pw/"  target="_black">S & A Wibsite </a>
                        </div>
                    </div>
                </div>    
            </div>

            <div className="row mt-4">
                <div className="col-md-4">

                    <div className="card  action-on-right card-color hover">
                        <div className="card-header">
                            Netsys And Computer
                        </div>
                        <div className="card-body">
                            หลังจากพัฒนา Website ให้ทางสาขาที่ สปป.ลาวเสร็จทางบริษัทพึงพอใจมากเลยให้มาพัฒนาเว็บของบริษัทต่อให้มีความสวยงาม ซึ่งเป็นการปรับปรุงเว็บให้สวยและทันสมัยขึ้น และอัพเดทข้อมูล Content อัพเดทใหม่
                        </div>
                        <div className="card-footer">
                            Link to : <a href="http://www.netsys.co.th"  target="_black">Netsys And Computer </a>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}
export default MyWorks;
