import { useEffect, useState } from "react"

function Login_Manual(){
    const [ getUsername, setUsername  ] = useState("")
    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            formData.append('u_username',getUsername)
            const app = {
                u_username: getUsername
            }
            let response = await fetch('http://localhost:8000/authentication/login/user',{
                method: 'post',
                  headers: {
                'Content-Type': 'application/json',
            },
                body: JSON.stringify(app),
                
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    
    };

    useEffect(()=>{
        
    },[])
    return (
        <>
            <form onSubmit={handleLogin}>
                <div className="login">
                    <label htmlFor="username">Username</label>
                    <input type="text" name="username" id="username" onChange={(e=>setUsername(e.target.value))} className="form-control" />
                    <button type="submit" className="btn btn-primary">Login</button>
                </div>
            </form>
        </>
    )
}
export default Login_Manual